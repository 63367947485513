import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { axios } from '~/src/lib/axios'
import { Post } from '~/src/types/api'
import { useInterval } from '~/src/hooks'
import { PostViewerThumbnail } from './PostViewerThumbnail'
import { PostViewerSidebar } from './PostViewerSidebar'

const PostViewer: React.FC = () => {
  const [cursor, setCursor] = useState(0)

  const [post, setPost] = useState<Post | null>()

  const { data: posts, isLoading } = useQuery(
    'posts',
    () => axios.get('/posts').then((res) => res.data.response.posts),
    { onSuccess: (posts) => setPost(posts[0]) }
  )

  const { data: media } = useQuery(
    ['/medias/', post?.id],
    () => axios.get(`/medias/${post?.mediaId}`).then((res) => res.data.response.media),
    { enabled: Boolean(post) }
  )

  const { data: user } = useQuery(
    ['/users', post?.user.username],
    () => axios.get(`/users/${post?.user.username}`).then((res) => res.data.response.user),
    { enabled: Boolean(post) }
  )

  const hasFullData = post && media && user

  useInterval(
    () => {
      const next = cursor === posts.length - 1 ? 0 : cursor + 1
      setCursor(next)
      setPost(posts[next])
    },
    hasFullData ? 6000 : null
  )

  if (!hasFullData) {
    return null
  }

  return (
    <>
      <PostViewerThumbnail post={post} media={media} user={user} />
      <PostViewerSidebar post={post} user={user} />
    </>
  )
}

export { PostViewer }
