import React, { useMemo } from 'react'
import styled from 'styled-components'
import { theme } from '~/src/theme'
import { Post, Media, User } from '~/src/types/api'
import { getUserFullName } from './utils'

interface Props {
  post: Post
  media: Media
  user: User
}

const PostViewerThumbnail: React.FC<Props> = ({ post, media, user }) => {
  const mediaSrcSet = useMemo(() => {
    if (!media) return ''
    return [
      `${media.urls.small} ${theme.screens.sm}w`,
      `${media.urls.regular} ${theme.screens.md}w`,
      `${media.urls.full} ${theme.screens.lg}w`
    ].join(', ')
  }, [media])

  return (
    <Backdrop>
      <Thumbnail
        src={media.urls.full}
        srcSet={mediaSrcSet}
        alt={post.description || `Media from ${getUserFullName(user)}'s post`}
      />
    </Backdrop>
  )
}

const Backdrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: ${theme.app.post.backdropMobileMaxHeight}px;
  background: ${theme.app.post.backdropBg};
  z-index: ${theme.zIndex.postBackdrop};
  overflow: hidden;

  @media ${theme.breakpoints.md} {
    height: 100vh;
    max-height: initial;
    padding-right: ${theme.app.post.sidebarWidth}px;
  }
`

const Thumbnail = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`

export { PostViewerThumbnail }
