import 'sanitize.css'

import React from 'react'
import { QueryClientProvider } from 'react-query'
import { client } from '~/src/lib/react-query'
import { GlobalStyle } from '~/src/components'
import { PostViewer } from './PostViewer'

const App = () => {
  return (
    <>
      <QueryClientProvider client={client}>
        <PostViewer />
      </QueryClientProvider>

      <GlobalStyle />
    </>
  )
}

export { App }
