import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://apis.slstice.com/mock'
})

instance.interceptors.request.use((request) => {
  if (!request.params) request.params = {}
  request.params.api_key = 'ZSTYF0GBSSF0l3Ou6DTPE'
  return request
})

export { instance as axios }
