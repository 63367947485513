import { useEffect } from 'react'
import { useLatestValue } from './useLatestValue'

const useInterval = (callback: Function, interval: number | null) => {
  const callbackRef = useLatestValue(callback)

  useEffect(() => {
    if (interval == null) return

    const n = setInterval(() => {
      callbackRef.current()
    }, interval)

    return () => {
      clearInterval(n)
    }
  }, [callbackRef, interval])
}

export { useInterval }
