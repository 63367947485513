import React, { useState, useMemo } from 'react'
import { formatDistanceToNowStrict } from 'date-fns'
import styled from 'styled-components'
import { theme } from '~/src/theme'
import { Icon } from '~/src/components'
import { pluralize } from '~/src/utils'
import { Post, User } from '~/src/types/api'
import { getUserFullName } from './utils'

interface Props {
  post: Post
  user: User
}

const PostViewerSidebar: React.FC<Props> = ({ post, user }) => {
  const dateAgo = useMemo(() => {
    return formatDistanceToNowStrict(new Date(post.created))
  }, [post])

  return (
    <Sidebar>
      <UserInfo>
        <Avatar src={user.profile_images.large} />
        <UserDetails>
          <UserName>{getUserFullName(user)}</UserName>
          <PostDate>Posted {dateAgo} ago</PostDate>
        </UserDetails>
      </UserInfo>

      {Boolean(post.title) && Boolean(post.description) && (
        <PostDetails>
          {Boolean(post.title) && <Title>{post.title}</Title>}
          {Boolean(post.description) && <Description>{post.description}</Description>}
        </PostDetails>
      )}

      <LikeBar>
        <LikeBarIcon active={Boolean(post.likes)}>
          <Icon name="thumb-up" width={14} />
        </LikeBarIcon>
        {post.likes > 0 && (
          <LikeBarText>
            {post.likes} {pluralize('like', post.likes)}
          </LikeBarText>
        )}
      </LikeBar>
    </Sidebar>
  )
}

const Sidebar = styled.div`
  padding: 16px;
  background: ${theme.colors.neutral[100]};
  z-index: ${theme.zIndex.postSidebar};

  @media ${theme.breakpoints.md} {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: ${theme.app.post.sidebarWidth}px;
  }
`

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

const Avatar = styled.img`
  margin-right: 16px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
`

const UserDetails = styled.div`
  line-height: 1;
`

const UserName = styled.div`
  line-height: 1;
  font-weight: 500;
`

const PostDate = styled.span`
  font-size: 12px;
  color: ${theme.colors.neutral[500]};
`

const PostDetails = styled.div`
  margin-bottom: 24px;
`

const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 4px;
  line-height: 1;
  font-size: ${theme.fontSizes.lg}px;
  font-weight: 500;
`

const Description = styled.p`
  line-height: 1.5;
`

const LikeBar = styled.div`
  display: flex;
  align-items: center;
`

const LikeBarIcon = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  height: 24px;
  width: 24px;
  color: ${theme.colors.neutral[50]};
  background: ${(props) => (props.active ? theme.colors.blue[500] : theme.colors.neutral[500])};
  border-radius: 50%;
`

const LikeBarText = styled.span`
  font-size: 12px;
  color: ${theme.colors.neutral[500]};
`

export { PostViewerSidebar }
